import Chart from 'chart.js';
import ProgressBar from 'progressbar.js';
import moment from 'moment';
import { getDaysArray } from "./helpers/dates";
// import Datamap from 'datamaps';
// import { formatDataToMap, exampleMapData } from './helpers/mapHelper.js';

/**
 * Common class for connecting charts on page
 */
class CheckinsCharts {

	// Scope Variables
	interval = null;
	chartData = null;
	charts = [];

	constructor() {
		// Init 
		// this.fakeData();
		this.getElement('#realtime-checkins', elm => {
			this.getDatamaps();
	
			// Interval for 5s
			this.interval = setInterval(() => {
				this.getDatamaps();
			}, 5000);
		})
	}

	getDatamaps() {
		fetch(`${window.location.href}/data`)
			.then(res => res.json())
			.then(res => {
				this.chartData = res;

				this.salesBar();
				this.progressBarJs();
				this.setNumbers();
				this.setTable();
			})
			.catch(err => {
				console.warn(err)
				clearInterval(this.interval);
				alert('Ocorreu um erro ao processar os dados...')
			})
	}
	
	/**
	 * High Order Function for getting HTML container
	 * 
	 * @param  {string}   selector [description]
	 * @param  {Function} callback [description]
	 * @return {void}
	 */
	getElement(selector, callback) {
		const chart = document.querySelector(selector);

		if (chart) 
			callback(chart);
	}

	setChart(selector, newData, config) {
		const chart = document.querySelector(selector);

		if (chart) {
			chart.style.display = 'block';
			// Verifica se não existe, para criar
			var chartElem = null;
			let hasElem = this.charts.filter(obj => obj.name == selector)
			
			if (hasElem.length === 0) {


				switch (config.type) {
					case 'line':
						chartElem = new ProgressBar.Line(chart, config);
						break;

					case 'circle':
						chartElem = new ProgressBar.Circle(chart, config);
						break;

					case 'lines':
						let newConfig = {...config};
						newConfig.type = 'line';
						newConfig.data = newData;
						chartElem = new Chart(chart, newConfig);
						break;
				
					default:
						break;
				}

				// Adiciona no array para reaproveitamento
				this.charts.push({
					name: selector,
					chart: chartElem
				});
			} else {
				// Retorna o que existe no Array
				chartElem = hasElem[0].chart;
			}

			if (chartElem) {
				if (config.type == 'circle') {
					chartElem.text.style.fontSize = '24px';
					chartElem.text.style.fontWeight = 'bold';
				}

				if (config.type == 'line' || config.type == 'circle') {
					chartElem.animate(newData);
				}
			}
		}
	}

	setNumbers() {
		this.getElement('#vendidosText1', elm => {
			if (this.chartData.ticket_items) elm.innerText = `${this.chartData.ticket_items.used}/${this.chartData.ticket_items.total}`;
		});
		this.getElement('#availableText1', elm => {
			if (this.chartData.ticket_items) elm.innerText = `${this.chartData.ticket_items.available}/${this.chartData.ticket_items.total}`;
		});
		this.getElement('#checkinsText1', elm => {
			if (this.chartData.ticket_items) elm.innerText = `${this.chartData.checkins.total}/${this.chartData.ticket_items.used}`;
		});
	}

	setTable() {
		this.getElement('#checkins-table', elm => {
			if (elm && this.chartData.checkins.list) {
				var table = `
					<table class="table">
						<thead>
							<tr>
								<th>Comprador</th>
								<th>Cupom</th>
								<th>Data do Checkin</th>
							</tr>
						</thead>
						<tbody>`;
						this.chartData.checkins.list.map(checkin => {
							table += `
								<tr>
									<td>${checkin.qr_code_data.order_data.customer_name}</td>
									<td>${checkin.qr_code_data.coupon}</td>
									<td>${moment(checkin.created_at).format("DD/MM/YYYY - HH:mm:ss")}</td>
								</tr>
							`;
						})
				table += `
					</tbody>
					<tfoot>
						<tr>
							<td colspan="3" class="text-center"><h3>...</h3></td>
						</tr>
					</tfoot>
				</table>`;
				elm.innerHTML = table;
			};
		});
	}

	// ProgressBar.js docs: 
	// http://progressbarjs.readthedocs.org/en/1.0.0/
	progressBarJs() {
		if (this.chartData.ticket_items) {
			const percentage = Math.floor(((this.chartData.ticket_items.used / this.chartData.ticket_items.total) * 100)) / 100;

			this.setChart('#vendidosBarChart1', percentage, {
				type: "line",
				strokeWidth: 2,
				easing: 'easeInOut',
				duration: 1400,
				color: '#3884ff',
				trailColor: '#f5f7f9',
				trailWidth: 2,
				strokeWidth: 2,
				svgStyle: {width: '100%', height: '100%', borderRadius: '5px'}
			});

			this.setChart('#vendidosCircleChart1', percentage, {
				type: "circle",
				color: '#3884ff',
				strokeWidth: 20,
				trailWidth: 7,
				easing: 'easeInOut',
				duration: 1400,
				from: { color: '#3884ff', width: 7 },
				to: { color: '#3884ff', width: 7 },
				step: function(state, circle) {
					circle.path.setAttribute('stroke', state.color);
					circle.path.setAttribute('stroke-width', state.width);
					var value = Math.round(circle.value() * 100);
					if (value === 0) {
						circle.setText('');
					} else {
						circle.setText(`${value}%`);
					}
				}
			});
		}

		if (this.chartData.ticket_items && this.chartData.checkins) {
			const percentage2 = Math.floor(((this.chartData.checkins.total / this.chartData.ticket_items.used) * 100)) / 100;

			this.setChart('#checkinsBarChart1', percentage2, {
				type: "line",
				strokeWidth: 2,
				easing: 'easeInOut',
				duration: 1400,
				color: '#f58423',
				trailColor: '#f5f7f9',
				trailWidth: 2,
				strokeWidth: 2,
				svgStyle: {width: '100%', height: '100%', borderRadius: '5px'}
			});

			this.setChart('#checkinsCircleChart1', percentage2, {
				type: "circle",
				color: '#f58423',
				strokeWidth: 20,
				trailWidth: 7,
				easing: 'easeInOut',
				duration: 1400,
				from: { color: '#f58423', width: 7 },
				to: { color: '#f58423', width: 7 },
				step: function(state, circle) {
					circle.path.setAttribute('stroke', state.color);
					circle.path.setAttribute('stroke-width', state.width);
					var value = Math.round(circle.value() * 100);
					if (value === 0) {
						circle.setText('');
					} else {
						circle.setText(`${value}%`);
					}

				}
			});
		}

		if (this.chartData.ticket_items) {
			const percentage3 = Math.floor(((this.chartData.ticket_items.available / this.chartData.ticket_items.total) * 100)) / 100;

			this.setChart('#availableBarChart1', percentage3, {
				type: "line",
				strokeWidth: 2,
				easing: 'easeInOut',
				duration: 1400,
				color: '#38c12f',
				trailColor: '#f5f7f9',
				trailWidth: 2,
				strokeWidth: 2,
				svgStyle: {width: '100%', height: '100%', borderRadius: '5px'}
			});

			this.setChart('#availableCircleChart1', percentage3, {
				type: "circle",
				color: '#38c12f',
				strokeWidth: 20,
				trailWidth: 7,
				easing: 'easeInOut',
				duration: 1400,
				from: { color: '#38c12f', width: 7 },
				to: { color: '#38c12f', width: 7 },
				step: function(state, circle) {
					circle.path.setAttribute('stroke', state.color);
					circle.path.setAttribute('stroke-width', state.width);
					var value = Math.round(circle.value() * 100);
					if (value === 0) {
						circle.setText('');
					} else {
						circle.setText(`${value}%`);
					}
				}
			});
		}
	}

	salesBar() {

		if (this.chartData.orders) {

			let dayOrders = this.chartData.orders.days;

			// Verifica se há mais de 1 item no dia
			if (dayOrders.length > 0) {
				var dateOffset = (24*60*60*1000); // 1 day

				let start = new Date(dayOrders[0].date);
				start.setTime(start.getTime() - (dateOffset * 1)); // - 1 day

				let end = new Date(dayOrders[dayOrders.length-1].date);
				end.setTime(end.getTime() + (dateOffset*2)); // + 2 days

				let rangeDates = getDaysArray(
					start,
					end
				);
	
				let finishedOrders = this.chartData.orders.finished;
				let canceledOrders = this.chartData.orders.canceled;

				var labels = [];
				var finishedData = [];
				var canceledData = [];

				rangeDates.map(day => {
					let nday = moment(day).format("YYYY-MM-DD");
					labels.push(moment(day).format("DD/MM/YY"));

					// Finalizados
					let filterFinished = finishedOrders.filter(obj => obj.date == nday);
					finishedData.push((filterFinished.length > 0) ? filterFinished[0].count : 0);

					// Cancelados
					let filterCanceled = canceledOrders.filter(obj => obj.date == nday);
					canceledData.push((filterCanceled.length > 0) ? filterCanceled[0].count : 0);
				})	
	
				let newData = {
					labels: labels,
					datasets: [{
						label: 'Vendidos',
						data: finishedData,
						backgroundColor: 'rgba(0,0,0,0)',
						borderColor: 'rgba(56, 132, 255, 1)',
						pointBackgroundColor: 'rgba(56, 132, 255, 1)',
						borderWidth: 3,
						pointRadius: 3,
					},{
						label: 'Cancelados',
						data: canceledData,
						backgroundColor: 'rgba(0,0,0,0)',
						borderColor: '#c32027',
						pointBackgroundColor: '#c32027',
						borderWidth: 2,
						pointRadius: 2
					}]
				};

				// Remove overlay
				this.getElement('#salesChartJsContainer .overlay', elm => {
					elm.style.display = 'none';
				});
				this.setChart('#salesChartJs', newData, {
					type: 'lines',
					options: {
						tooltips: {
							mode: 'index',
							intersect: false,
						},
						scales: {
							xAxes: [{
								gridLines: {
									color: 'rgba(0, 0, 0, 0)'
								},
							}],
							yAxes: [{
								gridLines: {
									color: 'rgba(0, 0, 0, 0.04)'
								},
							}]
						}
					}
				});
			} else {
				this.getElement('#salesChartJsContainer .overlay', elm => {
					elm.style.display = 'flex';
				})
			}
		}

	}
}

export default CheckinsCharts;